import React, { useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'

export default () => {
  // Keeping this for future reference

  // const [previewData, setData] = useState(null);
  // const [slug, setSlug] = useQueryParam('page', StringParam)
  // console.log(slug)
  // const query = `query {
  //     pageCollection(preview: true, where: { slug: "fjarmal-og-arsreikningur"}) {
  //       items {
  //         slug
  //       }
  //     }
  //   }`

  // useEffect(() => {
  //   async function fetchData() {
  //     const data = await fetch(`https://graphql.contentful.com/content/v1/spaces/{SPACE_ID}`, {
  //       method: 'POST',
  //       headers: {
  //         Authorization: `Bearer `,
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ query }),
  //     }).then(response => response.json())
  //     setData(data);

  //   }
  //   fetchData()
  // }, [])

  return (
    <div>
      <h1>Preview virkni, virkar ekki eins og er</h1>
    </div>
  )
}
